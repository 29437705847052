import "@/styles/globals.css"
import Layout from "@/components/Layout/Layout"
import ThemeContextProvider from "@/components/Layout/ThemeContextProvider"
import SuperTokensReact, {SuperTokensWrapper} from "supertokens-auth-react"
import {frontendConfig} from "./../config/frontendConfig"
import posthog from "posthog-js"
import {PostHogProvider} from "posthog-js/react"
import {useEffect} from "react"
import {useRouter} from "next/router"
import Script from "next/script"
import type {AppProps} from "next/app"
import ProfileContextProvider from "@/contexts/profile.context"
import AppContextProvider from "@/contexts/app.context"
import OrgContextProvider from "@/contexts/org.context"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import Head from "next/head"
import {Inter} from "next/font/google"

const inter = Inter({subsets: ["latin"]})

if (typeof window !== "undefined") {
    SuperTokensReact.init(frontendConfig())
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY!, {
        api_host: "https://app.posthog.com",
        // Enable debug mode in development
        loaded: (posthog) => {
            if (process.env.NODE_ENV === "development") posthog.debug()
        },
        capture_pageview: false, // Disable automatic pageview capture, as we capture manually
        session_recording: {
            maskAllInputs: false,
            maskInputOptions: {
                password: true,
                email: true,
            },
        },
    })
}
export default function App({Component, pageProps}: AppProps) {
    const router = useRouter()
    useEffect(() => {
        // Track page views
        const handleRouteChange = () => posthog?.capture("$pageview")
        router.events.on("routeChangeComplete", handleRouteChange)

        return () => {
            router.events.off("routeChangeComplete", handleRouteChange)
        }
    }, [])
    return (
        <>
            <Head>
                <title>Agenta: The LLMOps platform.</title>
                <link rel="shortcut icon" href="/assets/favicon.ico" />
                <script
                    type="text/javascript"
                    src="https://app.termly.io/embed.min.js/8e05e2f3-b396-45dd-bb76-4dfa5ce28e10?autoBlock=on"
                ></script>
            </Head>
            <div className="container">
                <Script src="https://www.googletagmanager.com/gtag/js?id=G-PV7R8H9KDM" />
                <Script id="google-analytics">
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'G-PV7R8H9KDM');
        `}
                </Script>
            </div>

            <main className={inter.className}>
                <PostHogProvider client={posthog}>
                    <SuperTokensWrapper>
                        <ThemeContextProvider>
                            <ProfileContextProvider>
                                <OrgContextProvider>
                                    <AppContextProvider>
                                        <Layout>
                                            <Component {...pageProps} />
                                        </Layout>
                                    </AppContextProvider>
                                </OrgContextProvider>
                            </ProfileContextProvider>
                        </ThemeContextProvider>
                    </SuperTokensWrapper>
                </PostHogProvider>
            </main>
        </>
    )
}
