import ThirdPartyPasswordless from "supertokens-auth-react/recipe/thirdpartypasswordless"
import SessionReact from "supertokens-auth-react/recipe/session"
import {appInfo} from "./appInfo"
import Router from "next/router"

export const frontendConfig = () => {
    return {
        appInfo,
        recipeList: [
            ThirdPartyPasswordless.init({
                getRedirectionURL: async (context) => {
                    if (context.action === "SUCCESS") {
                        if (process.env.NEXT_PUBLIC_FF === "cloud" && context.isNewUser) {
                            // user signed up
                            return "/post-signup"
                        }
                        if (context.redirectToPath !== undefined) {
                            // we are navigating back to where the user was before they authenticated
                            return context.redirectToPath
                        } else {
                            // user signed in
                            return "/apps"
                        }
                    }
                    return undefined
                },
                contactMethod: "EMAIL",
                signInUpFeature: {
                    providers: [
                        ThirdPartyPasswordless.Github.init(),
                        ThirdPartyPasswordless.Google.init(),
                    ],
                    termsOfServiceLink: "https://agenta.ai/terms-and-conditions-demo",
                    privacyPolicyLink: "https://agenta.ai/privacy-policy-demo",
                },
            }),
            SessionReact.init(),
        ],
        windowHandler: (oI: any) => {
            return {
                ...oI,
                location: {
                    ...oI.location,
                    setHref: (href: string) => {
                        Router.push(href)
                    },
                },
            }
        },
    }
}
